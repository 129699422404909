import React, {ReactNode, useEffect} from 'react';
import clsx from 'clsx';
import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import { StyleSidebarMenuItemWithSub } from './style';

type WithChildren = {
  children?: ReactNode
};

type Props = {
  href: string[]
  title: string
  icon?: ReactNode
  fontIcon?: string
  hasBullet?: boolean,
  iconType?: string,
  hideMenuTitleToggle?: boolean,
  setIsActive?: (isActive: boolean) => void;
};

export function getCurrentUrl(pathname: string) {
  return pathname?.split(/[?#]/)[0];
}

export function checkIsActive(pathname: string, urls: string | string[]) {
  const current = getCurrentUrl(pathname);

  if (!current || !urls) {
    return false;
  }

  const urlArray = Array.isArray(urls) ? urls : [urls];

  const isActive = urlArray.some((url) => {
    const allUrl = current?.split('/').filter((item) => item !== '').map((item) =>  `/${item}`);

    if (current === url || current?.indexOf(url) > -1 || allUrl?.includes(url)) {
      return true;
    }
    return false;
  });

  return isActive;
}

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  href,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  iconType,
  hideMenuTitleToggle = false,
  setIsActive,
}) => {
  const pathname = usePathname();
  const isActive = checkIsActive(pathname, href);
  const [show, setShow] = React.useState(false);

  const toggleExpand = () => {
    console.log('click on menu with sub');
    // check if hideMenuTitleToggle is true, then set isActive to true
    setShow(!show);
    if (hideMenuTitleToggle === false) {
      setIsActive && setIsActive(!hideMenuTitleToggle); // NOSONAR
      setShow(!show);
    }
  };

  useEffect(() => {
    if (isActive) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [isActive, pathname, href, pathname]);

  return (
    <StyleSidebarMenuItemWithSub
      className={classNames('px-2.5', {
        'menu-accordion here active': show,
        'py-2.5': !isActive,
        'active py-3': isActive,
        'menu-tooltip': !hideMenuTitleToggle,
      })}// NOSONAR
      onClick={toggleExpand}
      data-menu-trigger='click'
    >
      <span className={isActive === true ? ' menu-link px-2.5 py-3 active' : ' menu-link px-2.5 py-2.5'}>
        {hasBullet === true && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && (
          <>
            <span className='menu-icon'>
              {icon}
            </span>
            <span className="tooltip-menu">{title}</span>
          </>
        )}
        {fontIcon && iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        {hideMenuTitleToggle === true ? <span className=' '>{title}</span> : ''}
        {hideMenuTitleToggle === true ? <span className="menu-arrow"></span>: ''}
      </span>
      <div className={`menu-sub menu-sub-accordion ${show === true ? "active":''}`}>
        {children}
      </div>
    </StyleSidebarMenuItemWithSub>
  );
};

export default SidebarMenuItemWithSub;
