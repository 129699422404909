/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { SidebarMenuItem, SidebarMenuItemWithSub } from '@components/modules';
import {
  FaHome,
  FaUserTie,
  FaRegWindowMaximize,
  FaRegImages,
  FaHeadSideCough,
  FaCodeBranch,
  FaRegCalendarPlus,
  FaRegFileAlt,
  FaRegFileArchive,
  FaChild,
} from 'react-icons/fa';
import { StyleSidebarMenu } from './style';

type SidebarMenuProps = {
  // children?: ReactNode
  isActive: boolean;
  setIsActive?: (isActive: boolean) => void;
};

const SidebarMenu = ({ isActive, setIsActive }: SidebarMenuProps) => {
  const [permission] = useState<any>(
    JSON.parse(localStorage.getItem('permission') ?? ''),
  );

  return (
    permission !== null && (
      <StyleSidebarMenu className="app-sidebar-menu">
        <div
          id="app_sidebar_menu_wrapper"
          className="app-sidebar-wrapper relative hover-scroll-overlay-y"
          style={{
            width: isActive === true ? '265px' : '75px',
            overflowX: isActive === true ? 'hidden' : 'unset',
            overflowY: isActive === true ? 'scroll' : 'unset',
          }}
        >
          <div
            className="menu menu-column menu-rounded menu-sub-indention px-3"
            id="#app_sidebar_menu"
            data-menu="true"
            data-menu-expand="false"
          >
            {permission.find(
              (doc: any) => doc.name_th === 'Dashboard ผู้ใช้งาน',
            ).permission_role.read === 1 ||
            permission.find((doc: any) => doc.name_th === 'Dashboard สมาธิ')
              .permission_role.read === 1 ? (
              <SidebarMenuItem
                href="/dashboard"
                hideMenuTitleToggle={isActive}
                icon={<FaHome />}
                title="แดชบอร์ด"
                fontIcon="bi-app-indicator"
              />
            ) : null}
            {permission.find((doc: any) => doc.name_th === 'แบนเนอร์')
              .permission_role.read === 1 && (
              <SidebarMenuItemWithSub
                hideMenuTitleToggle={isActive}
                href={['/main-banner', '/event-banner', '/news-banner']}
                icon={<FaRegImages />}
                title="แบนเนอร์"
                setIsActive={setIsActive}
              >
                <ul>
                  <li className="">
                    <SidebarMenuItem
                      hasBullet
                      hideMenuTitleToggle={isActive}
                      href="/main-banner"
                      title="แบนเนอร์หน้าหลัก"
                      fontIcon="bi-layers"
                    />
                  </li>
                  <li className="">
                    <SidebarMenuItem
                      hasBullet
                      hideMenuTitleToggle={isActive}
                      href="/event-banner"
                      title="แบนเนอร์กิจกรรม"
                      fontIcon="bi-layers"
                    />
                  </li>
                  <li className="">
                    <SidebarMenuItem
                      hasBullet
                      hideMenuTitleToggle={isActive}
                      href="/news-banner"
                      title="แบนเนอร์ข่าวสาร"
                      fontIcon="bi-layers"
                    />
                  </li>
                </ul>
              </SidebarMenuItemWithSub>
            )}
            {permission.find((doc: any) => doc.name_th === 'สาขา')
              .permission_role.read === 1 && (
              <SidebarMenuItem
                hideMenuTitleToggle={isActive}
                href="/branch"
                title="สาขา"
                fontIcon="bi-layers"
                icon={<FaCodeBranch />}
              />
            )}
            {permission.find((doc: any) => doc.name_th === 'FAQ')
              .permission_role.read === 1 ||
            permission.find((doc: any) => doc.name_th === 'หมวดหมู่ FAQ')
              .permission_role.read === 1 ? (
              <SidebarMenuItemWithSub
                hideMenuTitleToggle={isActive}
                href={['/faq', '/faq-type']}
                icon={<FaHeadSideCough />}
                title="FAQ"
                setIsActive={setIsActive}
              >
                <ul>
                  {permission.find((doc: any) => doc.name_th === 'FAQ')
                    .permission_role.read === 1 && (
                    <li className="">
                      <SidebarMenuItem
                        hasBullet
                        hideMenuTitleToggle={isActive}
                        href="/faq"
                        title="FAQ ทั้งหมด"
                        fontIcon="bi-layers"
                      />
                    </li>
                  )}
                  {permission.find((doc: any) => doc.name_th === 'หมวดหมู่ FAQ')
                    .permission_role.read === 1 && (
                    <li className="">
                      <SidebarMenuItem
                        hasBullet
                        hideMenuTitleToggle={isActive}
                        href="/faq-type"
                        title="หมวดหมู่ FAQ"
                        fontIcon="bi-layers"
                      />
                    </li>
                  )}
                </ul>
              </SidebarMenuItemWithSub>
            ) : null}
            {permission.find((doc: any) => doc.name_th === 'แกลเลอรี่')
              .permission_role.read === 1 ||
              permission.find((doc: any) => doc.name_th === 'คณะผู้บริการ')
                .permission_role.read === 1 ? (
                <SidebarMenuItemWithSub
                  hideMenuTitleToggle={isActive}
                  href={['/gallery', '/team-member', '/board']}
                  icon={<FaRegWindowMaximize />}
                  title="หน้าในเว็บไซต์"
                  setIsActive={setIsActive}
                >
                  <ul>
                    {permission.find((doc: any) => doc.name_th === 'แกลเลอรี่')
                      .permission_role.read === 1 && (
                      <li className="">
                        <SidebarMenuItem
                          hasBullet
                          hideMenuTitleToggle={isActive}
                          href="/gallery"
                          title="แกลเลอรี่"
                          fontIcon="bi-layers"
                        />
                      </li>
                    )}
                    {permission.find(
                      (doc: any) => doc.name_th === 'คณะผู้บริการ',
                    ).permission_role.read === 1 && (
                      <li className="">
                        <SidebarMenuItem
                          hasBullet
                          hideMenuTitleToggle={isActive}
                          href="/team-member"
                          title="คณะผู้บริหาร"
                          fontIcon="bi-layers"
                        />
                      </li>
                    )}
                    {permission.find(
                      (doc: any) => doc.name_th === 'คณะกรรมการ',
                    ).permission_role.read === 1 && (
                      <li className="">
                        <SidebarMenuItem
                          hasBullet
                          hideMenuTitleToggle={isActive}
                          href="/board"
                          title="คณะกรรมการ"
                          fontIcon="bi-layers"
                        />
                      </li>
                    )}
                  </ul>
                </SidebarMenuItemWithSub>
              ): null}
            {permission.find((doc: any) => doc.name_th === 'เนื้อหาต่างๆ')
              .permission_role.read === 1 && (
              <SidebarMenuItem
                hideMenuTitleToggle={isActive}
                href="/article"
                title="เนื้อหาต่างๆ"
                fontIcon="bi-layers"
                icon={<FaRegFileAlt />}
              />
            )}
            {permission.find((doc: any) => doc.name_th === 'กิจกรรม')
              .permission_role.read === 1 ||
            permission.find((doc: any) => doc.name_th === 'อบรม')
              .permission_role.read === 1 ||
            permission.find((doc: any) => doc.name_th === 'สมาธิแคมเปญ')
              .permission_role.read === 1 ? (
              <SidebarMenuItemWithSub
                hideMenuTitleToggle={isActive}
                href={['/event', '/course']}
                icon={<FaRegCalendarPlus />}
                title="กิจกรรมต่างๆ"
                setIsActive={setIsActive}
              >
                <ul>
                  {permission.find((doc: any) => doc.name_th === 'กิจกรรม')
                    .permission_role.read === 1 && (
                    <li className="">
                      <SidebarMenuItem
                        hasBullet
                        hideMenuTitleToggle={isActive}
                        href="/event"
                        title="กิจกรรม"
                        fontIcon="bi-layers"
                      />
                    </li>
                  )}
                  {permission.find((doc: any) => doc.name_th === 'อบรม')
                    .permission_role.read === 1 && (
                    <li className="">
                      <SidebarMenuItem
                        hasBullet
                        hideMenuTitleToggle={isActive}
                        href="/course"
                        title="อบรม"
                        fontIcon="bi-layers"
                      />
                    </li>
                  )}
                  {permission.find((doc: any) => doc.name_th === 'สมาธิแคมเปญ')
                    .permission_role.read === 1 && (
                    <li className="">
                      <SidebarMenuItem
                        hasBullet
                        hideMenuTitleToggle={isActive}
                        href="/samathi-campaign"
                        title="สมาธิแคมเปญ"
                        fontIcon="bi-layers"
                      />
                    </li>
                  )}
                </ul>
              </SidebarMenuItemWithSub>
            ) : null}
            {permission.find((doc: any) => doc.name_th === 'ผู้ใช้งานทั่วไป')
              .permission_role.read === 1 ||
            permission.find(
              (doc: any) => doc.name_th === 'ปรับสิทธิ์แอพพลิเคชั่น',
            ).permission_role.read === 1 ? (
              <SidebarMenuItemWithSub
                hideMenuTitleToggle={isActive}
                href={['/users', '/app-permission']}
                icon={<FaUserTie />}
                title="ข้อมูลผู้ใช้งาน"
                setIsActive={setIsActive}
              >
                <ul>
                  {permission.find(
                    (doc: any) => doc.name_th === 'ผู้ใช้งานทั่วไป',
                  ).permission_role.read === 1 && (
                    <li className="">
                      <SidebarMenuItem
                        hasBullet
                        hideMenuTitleToggle={isActive}
                        href="/users"
                        title="ผู้ใช้งานทั่วไป"
                        fontIcon="bi-layers"
                      />
                    </li>
                  )}
                  {permission.find(
                    (doc: any) => doc.name_th === 'ปรับสิทธิ์แอพพลิเคชั่น',
                  ).permission_role.read === 1 && (
                    <li className="">
                      <SidebarMenuItem
                        hasBullet
                        hideMenuTitleToggle={isActive}
                        href="/app-permission"
                        title="ปรับสิทธิ์แอพพลิเคชั่น"
                        fontIcon="bi-layers"
                      />
                    </li>
                  )}
                </ul>
              </SidebarMenuItemWithSub>
            ) : null}
            {permission.find((doc: any) => doc.name_th === 'ประเภทสมาชิก')
              .permission_role.read === 1 && (
              <SidebarMenuItem
                hideMenuTitleToggle={isActive}
                href="/pemissions"
                title="ประเภทสมาชิก"
                fontIcon="bi-layers"
                icon={<FaChild />}
              />
            )}
            {permission.find((doc: any) => doc.name_th === 'ประวัติการใช้งาน')
              .permission_role.read === 1 && (
              <SidebarMenuItem
                hideMenuTitleToggle={isActive}
                href="/activity-logs"
                title="ประวัติการใช้งาน"
                fontIcon="bi-layers"
                icon={<FaRegFileArchive />}
              />
            )}
          </div>
        </div>
      </StyleSidebarMenu>
    )
  );
};

export default SidebarMenu;
